.splash-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    position: absolute;
}

.splash-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f3460;
    padding: 35px;
    border-radius: 10px;
    max-width: 600px;
    margin-top: 50px;
}
