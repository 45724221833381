.home {
    width: 100%;
    height: 100%;
    margin-top: 90px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-index-container {
    padding: 20px;
    margin: 20px;
    background-color: #0f3460;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 2px 4px 10px black;
}

.home-index-item {
    width: 90%;
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 22px;
    transition: 0.2s;
    border-radius: 10px;
}

.home-index-item > i {
    font-size: 30px;
    margin-right: 8px;
}

.home-index-item:hover {
    background-color: rgba(143, 233, 255, 0.096);
    color: rgb(210, 242, 255);
}

.home-title {
    font-size: 25px;
    color: white;
    font-family: comfortaa;
    font-weight: 200;
}

.home-index-item-text {
    height: 100%;
    vertical-align: middle;
    font-family: comfortaa;
    font-weight: 100;
    text-align: center;
    margin: 5px;
}
