.register-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 90px;
}

.register-form {
    margin: 20px;
    padding: 20px;
}
